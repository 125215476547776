import { colors } from '@/constants';
import styled from 'styled-components';
interface Props {
  isOpenGuide: boolean;
}
export const BlockListStyled = styled.div<Props>`
  .blocklist-wrapper {
    display: ${(props) => (props.isOpenGuide ? 'block' : 'none')};
    position: fixed;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #303030;
    opacity: 0.5;
    z-index: 501;
  }
  .add-rule-wrapper {
    z-index: 502;
    position: relative;
    .Polaris-LegacyTabs__Tab--selected {
      .Polaris-LegacyTabs__Title {
        background-color: rgba(0, 0, 0, 0.06);
        color: #303030;
        &::before {
          display: none;
        }
      }
    }
    .Polaris-LegacyTabs__Tab {
      .Polaris-LegacyTabs__Title {
        font-weight: 500;
        font-size: 12px;
      }
      &:hover {
        .Polaris-LegacyTabs__Title {
          background-color: rgba(0, 0, 0, 0.06);
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .table-content {
    justify-content: space-between;
    overflow: auto;
  }
  .header {
    .Polaris-Icon {
      margin: 0% 8px;
    }
  }
  .d-flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  nav {
    display: flex;
    justify-content: center;
  }
  .add-rule-container {
    background-color: white;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    min-height: 115px;
    &:hover {
      background-color: ${colors.gray_background};
    }
  }
  .add-rule-img {
    margin-bottom: 8px;
    max-width: 100%;
    height: 50px;
  }
  .add-rule-title {
    align-items: center;
    .Polaris-Icon {
      margin: 0;
    }
  }
  .Polaris-Filters-ConnectedFilterControl__AuxiliaryContainer {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mr-4 {
    margin-right: 4px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .mt-4 {
    margin-top: 4px;
  }
  .div-disable {
    pointer-events: none;
    opacity: 0.5;
  }
  .link {
    font-weight: 502;
    color: ${colors.link};
  }
  .btn-container {
    display: flex;
    align-items: center;
    position: relative;
    min-width: 90px;
    .absolute {
      position: absolute;
      top: 0;
      left: 2.25rem;
    }

    .control-btn {
      display: none;
      height: 20px;
      cursor: pointer;
      button {
        border: none;
        background: transparent;
        svg {
          fill: rgba(138, 138, 138, 1);
        }
        padding: 0;
      }
    }
    .control-btn-toggle {
      display: block;
      cursor: pointer;
    }
    .remove-btn {
      &:hover {
        border-color: ${colors.critical};
        svg {
          fill: ${colors.critical};
        }
      }
    }
    .edit-btn {
      &:hover {
        border-color: ${colors.critical};
        svg {
          fill: ${colors.primary};
        }
      }
    }
    /* CSS toggle switch */
    .rule-toggle {
      cursor: pointer;
      display: inline-block;
      position: relative;
      width: 32px;
      height: 18px;
      border-radius: 1rem;
      border: 1px solid #ddd;
      background: #ddd;
      &:after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        position: absolute;
        left: 1px;
        top: 1px;
        transition: 0.3s;
        background: #fff;
      }
      &.active {
        background: ${colors.primary};
        &:after {
          left: calc(100% - 15px);
        }
      }
    }
  }
  .Polaris-IndexTable__TableRow {
    &:hover {
      .control-btn {
        display: block;
        .Polaris-Icon {
          cursor: pointer;
        }
      }
    }
  }
  .Polaris-LegacyCard__Section {
    padding: 1rem;
    padding-top: 0;
  }

  .card-table {
    .Polaris-IndexTable__TableCell--first {
      padding: 8px 8px 8px 12px !important;
      min-width: 38px;
    }
    .Polaris-IndexTable__TableHeading--first {
      padding: 8px 8px 8px 12px !important;
      min-width: 38px;
    }
    .Polaris-IndexTable__TableCell--first .Polaris-IndexTable-Checkbox__TableCellContentContainer {
      display: flex;
    }
    margin-top: 1rem;
    position: relative;
    .Polaris-IndexFilters-Container {
      padding-right: 5.5rem;
      padding-left: 0.25rem;
    }
    .btn-delete {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      z-index: 10;
    }
    .btn-export {
      position: absolute;
      top: 0.5rem;
      right: 3.5rem;
      z-index: 10;
    }
  }
  .w-600 {
    .Polaris-Text--root {
      max-width: 600px;
      white-space: pre-line;
    }
  }
  .w-400 {
    .Polaris-Text--root {
      max-width: 400px;
      white-space: pre-line;
    }
  }
  .note {
    padding: 0 12px;
  }
`;
