/* eslint-disable react-hooks/exhaustive-deps */
import { images } from '@/asset';
import { config } from '@/config';
import { LINK, PATH } from '@/constants';
import { apiCaller } from '@/redux/query';
import { ILayout } from '@/types/components';
import { Button, EmptyState, Frame, LegacyCard, Link, Loading, Page } from '@shopify/polaris';
import { AnalyticsMinor, HomeMajor, SettingsMajor, ToolsMajor, QuestionMarkMajor } from '@shopify/polaris-icons';
import { memo, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RegularText from '../RegularText';
import Toast from '../common/Toast';
import { LayoutStyled } from './styled';
import SkeletonPage from '../SkeletonPage';

const btnGroup = [
  {
    content: 'Home',
    icon: HomeMajor,
    path: PATH.HOME,
  },
  {
    content: 'Visitor Analytics',
    icon: AnalyticsMinor,
    path: PATH.DASHBOARD,
  },
  {
    content: 'Blocker & Re-director ',
    icon: ToolsMajor,
    path: PATH.BLOCKLIST,
  },
  {
    content: 'Settings',
    icon: SettingsMajor,
    path: PATH.SETTINGS,
  },
  {
    content: 'FAQS',
    icon: QuestionMarkMajor,
    path: PATH.FAQS,
  },
];

const Layout = ({ children, layoutProps, isVisibleHeader = true }: ILayout.IProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, isLoading } = apiCaller.useGetGeneralDetailQuery(config.shop);
  const handleOpenPublishSite = () => {
    window.open(data?.settings.urlSiteEditor);
  };
  useLayoutEffect(() => {
    if (data) {
      if (data.settings.displayWelcome) {
        navigate(PATH.WELCOME);
      }
    }
  }, [data]);
  return (
    <LayoutStyled>
      <Frame>
        {isLoading ? <Loading /> : null}
        <Toast />
        {isVisibleHeader ? (
          <div className="nav-bar">
            {btnGroup.map((item, index) => {
              return (
                <div key={index} style={{ marginLeft: '0.25rem' }}>
                  <Button
                    icon={item.icon}
                    variant={
                      location.pathname === item.path.pathname || (index === 0 && location.pathname === '/')
                        ? 'primary'
                        : undefined
                    }
                    onClick={() => {
                      navigate({
                        ...item.path,
                      });
                    }}
                  >
                    {item.content}
                  </Button>
                </div>
              );
            })}
          </div>
        ) : null}
        {data && !data.settings.isPublishSite ? (
          <div style={{ width: '100%' }}>
            <LegacyCard sectioned>
              <EmptyState
                heading="Oh no! It seems like your site is not published!"
                action={{ content: 'Publish from Wix site editor', onAction: handleOpenPublishSite }}
                image={images.publishSite}
              >
                <p>Please go to Wix's site editor and publish your site to use Blockify.</p>
              </EmptyState>
            </LegacyCard>
          </div>
        ) : (
          <Page {...layoutProps}>
            {isLoading ? (
              <div className="mt-16">
                <SkeletonPage />
              </div>
            ) : (
              <div>{children}</div>
            )}
            <div className="layout-footer">
              <RegularText>
                Share your experience with Blockify here{' '}
                <Link external url={LINK.wixReview}>
                  leave a review
                </Link>
              </RegularText>
            </div>
          </Page>
        )}
      </Frame>
    </LayoutStyled>
  );
};
export default memo(Layout);
