import { config } from '@/config';
import { Enum } from '@/constants';
import { formatDate, getLastMonth, getLastSomesMonth, getSomeDaysAgo } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { Box, Button, DatePicker, Icon, OptionList, Popover, TextField, Tooltip, useBreakpoints } from '@shopify/polaris';
import { ArrowRightMinor, CalendarMinor } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DatePickerContainer } from './styled';

interface Props {
  titleButton: string;
  setTitleButton: Function;
  startDate: Date;
  endDate: Date;
  onSave: Function;
  disabled?: boolean;
}
const startHourYesterday = new Date(getSomeDaysAgo(1));
startHourYesterday.setHours(0);
startHourYesterday.setMinutes(0);
startHourYesterday.setSeconds(0);
const endHourYesterday = new Date(getSomeDaysAgo(1));
endHourYesterday.setHours(23);
endHourYesterday.setMinutes(59);
endHourYesterday.setSeconds(59);
const now = new Date();
const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

function CustomDatePicker(props: Props) {
  const accountPlan = apiCaller.useGetGeneralDetailQuery(config.shop);
  const plan = accountPlan.data?.settings.user.plan;
  const ranges = useMemo(() => {
    return [
      {
        title: 'Custom',
        alias: 'Custom',
        titleWithTooltip: 'Custom',
        period: {
          end: endDate,
          start: startDate,
        },
      },
      {
        title: 'Today',
        alias: 'today',
        period: {
          end: endDate,
          start: startDate,
        },
        titleWithTooltip: 'Today',
      },
      {
        title: 'Yesterday',
        alias: 'yesterday',
        period: {
          end: endHourYesterday,
          start: startHourYesterday,
        },
        titleWithTooltip: 'Yesterday',
      },
      {
        title: 'Last 7 days',
        alias: 'last7days',
        period: {
          end: endDate,
          start: getSomeDaysAgo(7),
        },
        titleWithTooltip: 'Last 7 days',
      },
      {
        title: 'Last 30 days',
        alias: 'last30days',
        period: {
          end: endDate,
          start: getSomeDaysAgo(30),
        },
        disable: plan === Enum.UserPlan.FREE,
        titleWithTooltip: (
          <Tooltip content="Available on higher plan!">
            <span>Last 30 days</span>
          </Tooltip>
        ),
      },
      {
        title: 'Last month',
        alias: 'lastmonth',
        period: {
          end: getLastMonth().end,
          start: getLastMonth().start,
        },
        titleWithTooltip: (
          <Tooltip content="Available on higher plan!">
            <span>Last month</span>
          </Tooltip>
        ),
        disable: plan === Enum.UserPlan.FREE,
      },
    ];
  }, [plan]);

  const [popoverActive, setPopoverActive] = useState(false);
  const [selected, setSelected] = useState(ranges[3]);
  const [selectedDates, setSelectedDates] = useState({ start: props.startDate, end: props.endDate });
  const { mdDown } = useBreakpoints();
  const [{ month, year }, setDate] = useState({ month: now.getMonth(), year: now.getFullYear() });
  const handleMonthChange = useCallback((month: number, year: number) => setDate({ month, year }), []);
  const handleTime = () => {
    const start = new Date(selectedDates.start);
    start.setHours(0, 0);
    const end = new Date(selectedDates.end);
    end.setHours(23, 59, 59, 999);
    props.onSave(start, end);
  };
  useEffect(() => {
    const range = ranges.find((item) => item.title === props.titleButton);
    setSelected(range || ranges[3]);
    setSelectedDates({
      end: props.endDate,
      start: props.startDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.endDate, props.startDate, props.titleButton]);

  return (
    <Popover
      active={popoverActive}
      autofocusTarget="none"
      preferredAlignment="left"
      preferredPosition="below"
      fluidContent
      sectioned={false}
      fullHeight
      activator={
        <Button icon={CalendarMinor} onClick={() => setPopoverActive(!popoverActive)}>
          {props.titleButton}
        </Button>
      }
      onClose={() => {
        setSelectedDates({ start: props.startDate || new Date(), end: props.startDate || new Date() });
        setPopoverActive(false);
      }}
    >
      <DatePickerContainer>
        <OptionList
          options={ranges.map((range) => ({
            value: range.alias,
            label: plan === Enum.UserPlan.FREE ? range.titleWithTooltip : range.title,
            disabled: range.disable,
          }))}
          selected={[selected.alias]}
          onChange={(value) => {
            const range = ranges.find((range) => range.alias === value[0]) || ranges[0];
            setSelected(range);
            setSelectedDates({ ...range.period });
            handleMonthChange(range.period.start.getMonth(), range.period.start.getFullYear());
          }}
        />
        <Box padding={'400'} maxWidth={mdDown ? '320px' : '516px'}>
          <div className="d-flex">
            <div>
              <TextField
                role="combobox"
                label={'Since'}
                labelHidden
                prefix={<Icon source={CalendarMinor} />}
                value={formatDate(selectedDates.start)}
                readOnly
                autoComplete="off"
              />
            </div>
            <Icon source={ArrowRightMinor} />
            <div>
              <TextField
                role="combobox"
                label={'Until'}
                labelHidden
                prefix={<Icon source={CalendarMinor} />}
                value={formatDate(selectedDates.end)}
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <DatePicker
            month={month}
            year={year}
            selected={{
              start: selectedDates.start ? new Date(selectedDates.start) : getSomeDaysAgo(7),
              end: selectedDates.end ? new Date(selectedDates.end) : new Date(),
            }}
            onMonthChange={handleMonthChange}
            onChange={(value) => {
              setSelectedDates(value);
              setSelected(ranges[0]);
            }}
            multiMonth
            allowRange
            disableDatesAfter={now}
            disableDatesBefore={plan === Enum.UserPlan.FREE ? getSomeDaysAgo(7) : getLastSomesMonth(2).end}
          />
          <div className="date-picker-btn-group">
            <Button
              onClick={() => {
                setSelectedDates({ start: props.startDate || new Date(), end: props.endDate || new Date() });
                setPopoverActive(false);
              }}
            >
              Cancel
            </Button>
            <div className="ml-8">
              <Button
                variant="primary"
                onClick={() => {
                  setPopoverActive(false);
                  handleTime();
                  props.setTitleButton(
                    selected.title === 'Custom'
                      ? `${formatDate(selectedDates.start, 'D MMM YYYY')} - ${formatDate(selectedDates.end, 'D MMM YYYY')}`
                      : selected.title,
                  );
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Box>
      </DatePickerContainer>
    </Popover>
  );
}

export default CustomDatePicker;
