// StyledComponents.tsx
import { colors } from '@/constants';
import styled from 'styled-components';

export const AppCard = styled.div`
  img {
    width: 40px;
  }
  flex: 1;
  .Polaris-Text--bodySm {
    color: ${colors.link};
  }
`;
