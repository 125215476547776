import RegularText from '@/components/RegularText';
import CustomLayout from '@/components/layout';
import { apiCaller } from '@/redux/query';
import { BlockStack, Box, Button, Card, Collapsible, Link, List } from '@shopify/polaris';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { config } from '@/config';

interface FAQItem {
  label: string;
  extend: boolean;
  content: React.ReactNode;
}

const PricingFAQs: FC = () => {
  const { data } = apiCaller.useGetGeneralDetailQuery(config.shop);
  const link = `https://www.wix.com/apps/upgrade/${process.env.REACT_APP_APP_ID}?appInstanceId=${data?.settings.instanceId}`;

  const initialData: FAQItem[] = useMemo(
    () => [
      {
        label: '1. Can I use the app for free?',
        extend: false,
        content: (
          <RegularText>
            Yes, our app providing a Free plan with a limited access Block, Redirect and Whitelist rules (6 rules).
          </RegularText>
        ),
      },
      {
        label: '2. What can I do with the free plan?',
        extend: false,
        content: (
          <>
            <RegularText>Our app provides a free plan with a limited rules of these features following:</RegularText>
            <List type="bullet">
              <List.Item>Block: IP address, Location</List.Item>
              <List.Item>Redirect: IP address, Location</List.Item>
              <List.Item>Whitelist: IP address, Location</List.Item>
              <List.Item>Uses blocking template</List.Item>
            </List>
            <RegularText>
              To increase the limitation, please consider to our{' '}
              <Link url={link} target="_blank">
                paid plan
              </Link>
              .
            </RegularText>
          </>
        ),
      },
      {
        label: '3. How does this app pricing work?',
        extend: false,
        content: <RegularText>Our app app pricing is recurring charge based, we will be charged via Wix Payment.</RegularText>,
      },
      // {
      //   label: '4. Do I have trials for the paid plan?',
      //   extend: false,
      //   content: (
      //     <RegularText>
      //       Yes, at the moment, Blockify available 3-day Free trial for{' '}
      //       <Link url={link} target="_blank">
      //         paid plan
      //       </Link>
      //       .
      //     </RegularText>
      //   ),
      // },
    ],
    [link],
  );

  const [listData, setListData] = useState<FAQItem[]>([]);

  useEffect(() => {
    setListData(initialData);
  }, [initialData]);

  const handleToggle = (index: number) => {
    setListData((prevData) =>
      prevData.map((item, i) => (i === index ? { ...item, extend: !item.extend } : { ...item, extend: false })),
    );
  };

  return (
    <CustomLayout
      layoutProps={{
        title: 'Pricing FAQs',
      }}
    >
      <Card padding={{ xs: '400', lg: '600' }}>
        <BlockStack gap="500">
          {listData.map((question, index) => (
            <React.Fragment key={question.label}>
              <Button
                size="large"
                fullWidth
                textAlign="left"
                disclosure={question.extend ? 'up' : 'down'}
                onClick={() => handleToggle(index)}
              >
                {question.label}
              </Button>

              <Collapsible
                open={question.extend}
                id="basic-collapsible"
                transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
              >
                <Box padding="200">
                  <RegularText>{question.content}</RegularText>
                </Box>
              </Collapsible>
            </React.Fragment>
          ))}
        </BlockStack>
      </Card>
    </CustomLayout>
  );
};

export default PricingFAQs;
