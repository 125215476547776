import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IParamsApi } from '@/types/api/params.api';
import { IResponseApi } from '@/types/api/response.api';
import { config } from '@/config';
const endPoint = process.env.REACT_APP_API_END_POINT;
const shop = config.shop;
const urlParams = config.urlParams;

export const apiCaller = createApi({
  reducerPath: 'apiCaller',
  refetchOnMountOrArgChange: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: endPoint,
    prepareHeaders: (headers) => {
      headers.set('Access-Control-Allow-Origin', `*`);
      headers.set('Authorization', `${config.instance ? config.instance : ''}`);
      if (shop && urlParams) {
        headers.set('wix-auth-shop', shop || '');
        headers.set('wix-auth-urlParams', urlParams || '');
      }
      return headers;
    },
  }),
  tagTypes: ['blocklist', 'general', 'ipAccess', 'countRules', 'overview'],
  endpoints: (builder) => ({
    getGeneralDetail: builder.query<IResponseApi.IGeneralDetail, string | undefined>({
      query: () => {
        return {
          url: `setting/general-detail`,
          method: 'GET',
          params: {
            shop: shop,
          },
        };
      },
      providesTags: [{ type: 'general' }],
    }),

    overviewSettings: builder.query<IResponseApi.IOverViewSettings, void>({
      query: () => {
        return {
          url: `setting/overview`,
          method: 'GET',
        };
      },
      providesTags: [{ type: 'overview' }],
    }),

    activeProxyVpn: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IProxyVPN>({
      query: (input) => {
        return {
          url: `setting/proxy-vpn/active`,
          method: 'PUT',
          body: {
            status: input.status,
            type: input.type,
            url: input.url,
            riskCore: input.riskCore,
            allowPrivateRelay: input.allowPrivateRelay,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    activeContentProtection: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/protect-text-image`,
          method: 'PUT',
          body: {
            protectContents: input.protectContents,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    activeBehavior: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/behavior`,
          method: 'PUT',
          body: input,
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    activeAutoBlock: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/auto-block`,
          method: 'PUT',
          body: {
            autoBlock: input.autoBlock,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    activeTorBlock: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/tor-block`,
          method: 'PUT',
          body: {
            torBlock: input.torBlock,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    fetchSettingList: builder.query<IResponseApi.ISettingList, IParamsApi.IGetSettingList>({
      query: (input) => {
        return {
          url: 'setting/list',
          method: 'GET',
          params: { ...input },
        };
      },
      providesTags: [{ type: 'blocklist' }],
    }),

    deleteSetting: builder.mutation<IResponseApi.ICommonResponse, number>({
      query: (input) => {
        return {
          url: `setting/rule/${input}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'blocklist' }, { type: 'general' }, { type: 'countRules' }, { type: 'overview' }],
    }),
    deleteAllBlackListSetting: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IRemoveRulesBlockList>({
      query: (input) => {
        return {
          url: `setting/rules/black-list`,
          method: 'DELETE',
          params: { ...input },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }, { type: 'general' }, { type: 'countRules' }, { type: 'overview' }],
    }),
    deleteAllWhiteListSetting: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IRemoveRulesBlockList>({
      query: (input) => {
        return {
          url: `setting/rules/white-list`,
          method: 'DELETE',
          params: { ...input },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }, { type: 'general' }, { type: 'countRules' }, { type: 'overview' }],
    }),

    upsertRule: builder.mutation<IResponseApi.IUpsertRule, IParamsApi.IUpdateRule>({
      query: (input) => {
        return {
          url: `setting/rule/upsert`,
          method: 'PUT',
          body: { ...input },
        };
      },
      invalidatesTags: [
        { type: 'blocklist' },
        { type: 'ipAccess' },
        { type: 'general' },
        { type: 'countRules' },
        { type: 'overview' },
      ],
    }),

    uploadImage: builder.mutation<IResponseApi.IUploadImage, FormData>({
      query: (image) => {
        return {
          url: `file/upload-image`,
          method: 'POST',
          body: image,
        };
      },
    }),

    updateTemplate: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IUpdateCustomTemplate>({
      query: (input) => {
        return {
          url: `setting/general/block-template`,
          method: 'PUT',
          body: {
            ...input,
          },
        };
      },
      // invalidatesTags: [{ type: 'general' }],
    }),

    getConfigPriority: builder.query<IResponseApi.IGetConfigPriority, void>({
      query: () => {
        return {
          url: `setting/config-priority`,
          method: 'GET',
        };
      },
    }),

    updateConfigPriority: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IConfigPriority>({
      query: (input) => {
        return {
          url: `setting/config-priority`,
          method: 'PUT',
          body: {
            ...input,
          },
        };
      },
    }),

    fetchVisitorList: builder.query<IResponseApi.IVisitorList, IParamsApi.IGetVisitorLog>({
      query: (input) => {
        const { providers } = input;
        const params = { ...input };
        if (providers?.length) params.providers = JSON.stringify(providers);
        return {
          url: `visitor/list`,
          method: 'GET',
          params,
        };
      },
      providesTags: [{ type: 'ipAccess' }],
    }),

    fetchListAccessAll: builder.query<IResponseApi.IAllAccessList, IParamsApi.IGetVisitorLog>({
      query: (input) => {
        const { providers } = input;
        const params = { ...input };
        if (providers?.length) params.providers = JSON.stringify(providers);
        return {
          url: `visitor/list/all`,
          method: 'GET',
          params,
        };
      },
      providesTags: [{ type: 'ipAccess' }],
    }),

    fetchVisitorDetail: builder.query<IResponseApi.IDetailVisitorList, IParamsApi.IGetDetailVisitorLog>({
      query: (input) => {
        return {
          url: `visitor/details`,
          method: 'GET',
          params: { ...input },
        };
      },
    }),

    fetchChartAnalytics: builder.query<IResponseApi.IVisitorChart, IParamsApi.IGetChart>({
      query: (startDate) => {
        return {
          url: `visitor/analytics`,
          method: 'GET',
          params: { ...startDate },
        };
      },
    }),

    fetchVisitorBlockList: builder.query<IResponseApi.IBlockList, IParamsApi.IGetAddressBlock>({
      query: (input) => {
        const { providers } = input;
        const params = { ...input };
        if (providers?.length) params.providers = JSON.stringify(providers);
        return {
          url: `visitor/block/list`,
          method: 'GET',
          params,
        };
      },
      providesTags: [{ type: 'ipAccess' }],
    }),

    fetchBlockedDetail: builder.query<IResponseApi.IGetBlockedDetail, IParamsApi.IGetBlockedDetail>({
      query: (input) => {
        return {
          url: `visitor/block/details`,
          method: 'GET',
          params: { ...input },
        };
      },
      providesTags: [{ type: 'ipAccess' }],
    }),

    fetchChartBlock: builder.query<IResponseApi.IBlockChart, IParamsApi.IGetChart>({
      query: (startDate) => {
        return {
          url: `visitor/block/analytics`,
          method: 'GET',
          params: { ...startDate },
        };
      },
    }),

    handleCsv: builder.mutation<IResponseApi.IUploadCsv, IParamsApi.IUploadCSV>({
      query: (input) => {
        return {
          url: `setting/import`,
          method: 'POST',
          body: { data: input.data },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }, { type: 'general' }],
    }),

    downloadTemplate: builder.query<IResponseApi.IExport, undefined>({
      query: () => {
        return {
          url: `setting/import-template`,
          method: 'GET',
        };
      },
    }),

    updateWelcomeStep: builder.mutation<IResponseApi.ICommonResponse, number>({
      query: (step) => {
        return {
          url: `setting/welcome-step/update`,
          method: 'PUT',
          body: { step },
        };
      },
    }),

    getListISP: builder.query<IResponseApi.IListIsp, IParamsApi.IListIsp>({
      query: (input) => {
        return {
          url: `isp/list`,
          method: 'GET',
          params: { countryCode: input.countryCode, search: input.search },
        };
      },
    }),

    updateReview: builder.mutation<IResponseApi.ICommonResponse, boolean>({
      query: (status) => {
        return {
          url: `setting/review-status/update`,
          method: 'PUT',
          body: { status },
        };
      },
    }),

    createReview: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IReview>({
      query: (input) => {
        return {
          url: `review/create`,
          method: 'POST',
          body: { type: input.type, content: input.content },
        };
      },
    }),

    getAdminURL: builder.query<IResponseApi.IGetAdminURL, any>({
      query: () => {
        return {
          url: 'setting/site-access',
          method: 'GET',
        };
      },
    }),

    settingTimezone: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.ITimezone>({
      query: (input) => {
        return {
          url: 'setting/timezone',
          method: 'PUT',
          body: input,
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    getLinkTheme: builder.query<IResponseApi.IGetAdminURL, string>({
      query: () => {
        return {
          url: 'shopify/app-embed-url',
          method: 'GET',
        };
      },
    }),

    enableApp: builder.mutation<IResponseApi.ICommonResponse, boolean>({
      query: () => {
        return {
          url: 'setting/enable-app',
          method: 'PUT',
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    getCustomizeCode: builder.query<IResponseApi.IGetCustomizeCode, string>({
      query: () => {
        return {
          url: 'setting/code-customize',
          method: 'GET',
        };
      },
    }),

    activeRule: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.IActiveStatusBlock>({
      query: (input) => {
        return {
          url: 'setting/rule/update-state',
          method: 'PUT',
          body: {
            ...input,
          },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }],
    }),

    ruleVerify: builder.mutation<IResponseApi.ICheckRules, IParamsApi.IRuleVerify>({
      query: (input) => {
        return {
          url: 'setting/rule/verify',
          method: 'POST',
          body: {
            ...input,
          },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }],
    }),

    rulesSummary: builder.query<IResponseApi.IRulesSummary, IParamsApi.IRuleVerify>({
      query: (input) => {
        return {
          url: 'setting/rules/summary',
          method: 'GET',
          params: {
            type: input.type,
            criteria: input.criteria,
            priority: input.priority,
          },
        };
      },
      providesTags: [{ type: 'countRules' }],
    }),

    getCountryFilter: builder.query<IResponseApi.ICountryFilter, IParamsApi.ICountryFilter>({
      query: (input) => {
        return {
          url: 'visitor/countries',
          method: 'GET',
          params: {
            startDate: input.startDate,
            endDate: input.endDate,
          },
        };
      },
    }),

    trackingAction: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.ITrackingAction>({
      query: (input) => {
        return {
          url: `track-action/update-plan`,
          method: 'POST',
          body: { position: input.position },
        };
      },
    }),

    getProviders: builder.query<IResponseApi.IProviderFilter, IParamsApi.IGetProviderFilter>({
      query: (input) => {
        return {
          url: `visitor/providers`,
          method: 'GET',
          params: input,
        };
      },
    }),

    getLocations: builder.query<IResponseApi.ILocationFilter, IParamsApi.IGetProviderFilter>({
      query: (input) => {
        return {
          url: `visitor/states-cities`,
          method: 'GET',
          params: input,
        };
      },
    }),

    getCustomizeCSS: builder.query<string, string>({
      query: () => {
        return {
          url: `setting/customize-css`,
          method: 'GET',
        };
      },
    }),

    updateCustomizeCSS: builder.mutation<IResponseApi.ICommonResponse, IParamsApi.ICustomizeCSS>({
      query: (input) => {
        return {
          url: `setting/customize-css`,
          method: 'PUT',
          body: {
            ...input,
          },
        };
      },
    }),

    getBlockTemplateData: builder.query<IResponseApi.IGetDetailTemplate, void>({
      query: () => {
        return {
          url: `setting/block-template-data`,
          method: 'GET',
        };
      },
    }),
    getTemplates: builder.query<IResponseApi.IListTemplate, string | undefined>({
      query: () => {
        return {
          url: `setting/blocking-template`,
          method: 'GET',
          params: {
            shop: shop,
          },
        };
      },
    }),
    fetchBlockingTemplate: builder.query<IResponseApi.IGetDetailTemplate, IParamsApi.IDetailTemplate>({
      query: (input) => {
        return {
          url: `setting/blocking-template-detail`,
          method: 'GET',
          params: { ...input },
        };
      },
    }),
    getUserAgent: builder.query<IResponseApi.IUserAgents, void>({
      query: () => {
        return {
          url: 'setting/user-agent-info',
          method: 'GET',
        };
      },
    }),
  }),
});
