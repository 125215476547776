import logo from './logo.png';
import agent from './agent.png';
import book from './book.png';
import consentik from './consentik.png';
import synctrack from './synctrack.png';
import tiktok from './tiktok.png';

export const dashboardImage = {
  logo,
  agent,
  book,
  consentik,
  synctrack,
  tiktok
};
