// MoreAppsByUs.tsx
import { dashboardImage } from '@/asset/images/dashboard';
import BoldText from '@/components/BoldText';
import Card from '@/components/card';
import RegularText from '@/components/RegularText';
import { Button, InlineGrid } from '@shopify/polaris';
import { AppCard } from './styled';

function MoreAppsByUs() {
  const apps = [
    {
      logo: dashboardImage.tiktok,
      name: '1-step setup & manage multi TikTok Shop accounts from one dashboard',
      description: "Bring your Wix products to TikTok Shop without hassle. Enjoy real-time updates, automatic error reporting, and a single dashboard to manage your listings, inventory, and pricing.",
      link: 'https://www.wix.com/app-market/web-solution/tiktok-shop-connector-by-omega?appIndex=2&referral=collection&referralSectionName=newest-apps-v2'
    },
    {
      logo: dashboardImage.synctrack,
      name: 'Auto-sync PayPal tracking info & Stripe. Faster PayPal funds release and build trust with PayPal',
      description: "Synctrack adds tracking info to PayPal & Stripe automatically and instantly on autopilot. That helps you reduce Stripe and PayPal disputes, money on hold, PayPal limits, and reserves. Synctrack keeps your business transparent, saves time, and avoids PayPal disputes!",
      link: 'https://www.wix.com/app-market/synctrack'
    }
  ];

  return (
    <div className='mt-16'>
      <Card title='More apps by us'>
        <InlineGrid
          columns={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}
          gap={{ lg: '400', md: '400', xl: '400', sm: '400', xs: '400' }}
        >
          {apps.map((app, index) => (
            <AppCard key={index}>
              <img src={app.logo} alt={app.name} />
              <BoldText>{app.name}</BoldText>
              <div className='mt-8'>
                <RegularText>{app.description}</RegularText>
              </div>
              <div className='mt-8'>
                <Button
                  onClick={() => {
                    window.open(
                      app.link,
                      '_blank',
                      'noopener,noreferrer',
                    );
                  }}
                >
                  Get App
                </Button>
              </div>
            </AppCard>
          ))}
        </InlineGrid>
      </Card>
    </div>
  );
}

export default MoreAppsByUs;
