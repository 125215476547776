import { config } from '@/config';
import { Enum } from '@/constants';
import { disablePlan, handleToastMutation, validateUrl } from '@/helpers';
import useScope from '@/hooks/Scope';
import { apiCaller } from '@/redux/query';
import toastSlice from '@/redux/slice/toast.slice';
import { IParamsApi } from '@/types/api/params.api';
import { Button, ChoiceList, Icon, Link, RangeSlider, TextField, BlockStack, Tooltip } from '@shopify/polaris';
import { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomSettingToggle from '@/components/settingToggle';
import RegularText from '@/components/RegularText';
import { InfoMinor } from '@shopify/polaris-icons';
import Switch from '@/components/Switch';

const BlockerAndRedirector = () => {
  const scope = useScope();
  const { data, isFetching } = apiCaller.useGetGeneralDetailQuery(config.shop);
  const [activeVpn, activeVpnStatus] = apiCaller.useActiveProxyVpnMutation();
  const [activeAutoBlock, autoBlockStatus] = apiCaller.useActiveAutoBlockMutation();
  const [activeTorBlock, torBlockStatus] = apiCaller.useActiveTorBlockMutation();
  const [selectedMode, setSelectedMode] = useState<string[]>([data?.settings?.typeRestrictionVpnProxy || '1']);
  const [buttonClick, setButtonClick] = useState('');
  const dispatch = useDispatch();
  const [textFieldValue, setTextFieldValue] = useState(data?.settings?.linkRedirectVpnProxy || '');
  const [showRenderChildren, setShowRenderChildren] = useState(selectedMode.toString() === '2');
  const [rangeValue, setRangeValue] = useState(data?.settings.vpnRiskCore);
  const [allowPrivateRelayStatus, setAllowPrivateRelayStatus] = useState(data?.settings?.allowVpnFromPrivateRelay);

  const handleRangeSliderChange = useCallback((value: number) => setRangeValue(value), []);
  const handleChoiceListChange = (value: string[]) => {
    setSelectedMode(value);
    setShowRenderChildren(value.includes('2'));
  };
  const handleTextFieldChange = (value: string) => setTextFieldValue(value);
  const handleDisableBtn = useCallback((): boolean => {
    if ((data && disablePlan([Enum.UserPlan.FREE])) || scope.isViewOnly) return true;
    const caseUrl =
      (textFieldValue === data?.settings?.linkRedirectVpnProxy && rangeValue === data.settings.vpnRiskCore) ||
      !validateUrl(textFieldValue);
    const caseBlock = rangeValue === data?.settings.vpnRiskCore;
    const caseAllowPrivateStatus = allowPrivateRelayStatus === data?.settings.allowVpnFromPrivateRelay;
    return caseAllowPrivateStatus && selectedMode[0] === data?.settings?.typeRestrictionVpnProxy
      ? selectedMode[0] === Enum.ActionType.Block
        ? caseBlock
        : caseUrl
      : false;
  }, [data, rangeValue, scope.isViewOnly, selectedMode, textFieldValue, allowPrivateRelayStatus]);
  const handleActiveVpn = useCallback(() => {
    activeVpn({
      status: !data?.settings.activeVpnProxy,
      type: selectedMode.toString(),
      url: textFieldValue,
    }).then((res) => {
      if ('data' in res && res.data.state === 0) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      } else {
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
    });
    setButtonClick('1');
  }, [activeVpn, data?.settings.activeVpnProxy, dispatch, selectedMode, textFieldValue]);

  const toggleRule = useCallback((status: boolean) => {
    setAllowPrivateRelayStatus(status);
  }, []);

  const handleICloudRelay = () => {
    window.open('https://docs.ipblocker.io/faq/general-faq');
  };

  const handleActiveAutoBlock = () => {
    const autoBlock: IParamsApi.IContentProtection = {
      autoBlock: !data?.settings.autoBlock,
    };
    activeAutoBlock(autoBlock).then((res) => {
      if ('data' in res && res.data.state === 0) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      } else {
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
    });
    setButtonClick('2');
  };
  const handleActiveTorBlock = () => {
    const torBlock: IParamsApi.IContentProtection = {
      torBlock: !data?.settings.torBlock,
    };
    activeTorBlock(torBlock).then((res) => {
      if ('data' in res && res.data.state === 0) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      } else {
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
    });
    setButtonClick('3');
  };

  const handleSaveVpn = useCallback(() => {
    setButtonClick('1');
    activeVpn({
      status: data?.settings.activeVpnProxy || true,
      type: selectedMode.toString(),
      url: textFieldValue,
      riskCore: rangeValue,
      allowPrivateRelay: allowPrivateRelayStatus,
    }).then((res) => {
      if ('data' in res && res.data.state === 0) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      } else {
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
    });
  }, [activeVpn, data?.settings.activeVpnProxy, dispatch, selectedMode, textFieldValue, rangeValue, allowPrivateRelayStatus]);

  const renderChildren = () => (
    <div style={{ marginTop: '8px' }}>
      <TextField
        label=""
        labelHidden
        onChange={handleTextFieldChange}
        value={textFieldValue}
        autoComplete="off"
        prefix={textFieldValue ? null : 'https://'}
      />
    </div>
  );

  return (
    <div>
      {data ? (
        <div>
          <CustomSettingToggle
            title="Proxy and VPN blocker"
            settingToggleProps={{
              enabled: disablePlan([Enum.UserPlan.FREE]) ? false : data?.settings.activeVpnProxy,
              action: {
                loading: activeVpnStatus.isLoading || (isFetching && buttonClick === '1'),
                onAction: handleActiveVpn,
              },
            }}
            minActivePlan={Enum.UserPlan.PREMIUM}
            disableStatus={disablePlan([Enum.UserPlan.FREE]) || scope.isViewOnly}
          >
            <RegularText>
              Turn on to automatically block visitors who use{' '}
              <Link external target="_blank" url="https://docs.ipblocker.io/getting-started/proxy-and-vpn-blocker">
                Proxy or VPN.
              </Link>
              {data?.settings.activeVpnProxy ? (
                <div>
                  <div className="mt-16 mb-16">
                    <RangeSlider
                      output
                      label={
                        <>
                          <div className="d-flex setting-risk-score">
                            <RegularText>Risk score starts at</RegularText>
                            <div
                              className="pointer"
                              onClick={() =>
                                window.open('https://docs.ipblocker.io/getting-started/visitor-analytics#risk-score', '_blank')
                              }
                            >
                              <Icon source={InfoMinor} tone="subdued" />
                            </div>
                          </div>
                        </>
                      }
                      helpText="You can block IP addresses using VPN/Proxy based on a risk score. If you don't set it, we will automatically block all  VPN/Proxy IPs."
                      value={rangeValue || 0}
                      suffix={
                        <p
                          style={{
                            minWidth: '24px',
                            textAlign: 'right',
                          }}
                        >
                          {rangeValue}
                        </p>
                      }
                      min={0}
                      max={100}
                      step={1}
                      onChange={handleRangeSliderChange}
                    />
                  </div>
                  <ChoiceList
                    title="Mode"
                    choices={[
                      { label: 'Block', value: '1' },
                      { label: 'Redirect to', value: '2' },
                    ]}
                    selected={selectedMode}
                    onChange={handleChoiceListChange}
                  />
                  <div className="mt-8">
                    <BlockStack gap="300">
                      <RegularText>
                        Allow{' '}
                        <Tooltip content="iCloud Private Relay only available on Safari browser">
                          <Link onClick={handleICloudRelay}>iCloud Private Relay</Link>
                        </Tooltip>
                      </RegularText>
                      <Switch
                        onSwitch={(checked) => toggleRule(checked)}
                        isActive={allowPrivateRelayStatus}
                        isLoading={activeVpnStatus.isLoading || scope.isViewOnly}
                      />
                    </BlockStack>
                  </div>
                  {showRenderChildren && renderChildren()}
                  <div className="mt-8">
                    <Button
                      onClick={handleSaveVpn}
                      disabled={handleDisableBtn()}
                      variant="primary"
                      loading={activeVpnStatus.isLoading && buttonClick === '1'}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              ) : null}
            </RegularText>
          </CustomSettingToggle>

          <CustomSettingToggle
            title="Auto-block visitors"
            settingToggleProps={{
              enabled: disablePlan([Enum.UserPlan.FREE]) ? false : data.settings.autoBlock,
              action: {
                loading: autoBlockStatus.isLoading || (isFetching && buttonClick === '2'),
                onAction: handleActiveAutoBlock,
              },
            }}
            minActivePlan={Enum.UserPlan.PREMIUM}
            disableStatus={disablePlan([Enum.UserPlan.FREE]) || scope.isViewOnly}
          >
            <RegularText>
              Turn on to auto-block visitors even when they change their IP addresses but still use the same browser.
            </RegularText>
          </CustomSettingToggle>
          <CustomSettingToggle
            title="Tor blocker"
            settingToggleProps={{
              enabled: disablePlan([Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : data?.settings.torBlock,
              action: {
                loading: torBlockStatus.isLoading || (isFetching && buttonClick === '3'),
                onAction: handleActiveTorBlock,
              },
            }}
            minActivePlan={Enum.UserPlan.ENTERPRISE}
            disableStatus={disablePlan([Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM]) || scope.isViewOnly}
          >
            <RegularText>
              Turn on to automatically block visitors who use{' '}
              <Link removeUnderline url="https://docs.ipblocker.io/getting-started/tor-blocker" target="_blank">
                Tor.
              </Link>
            </RegularText>
          </CustomSettingToggle>
        </div>
      ) : null}
    </div>
  );
};
export default memo(BlockerAndRedirector);
