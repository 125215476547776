/* eslint-disable react-hooks/exhaustive-deps */
import CustomSettingToggle from '@/components/settingToggle';
import { config } from '@/config';
import { handleToastMutation } from '@/helpers';
import useScope from '@/hooks/Scope';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import toastSlice from '@/redux/slice/toast.slice';
import { Banner, Button, LegacyCard, LegacyStack, Link, Loading, Modal, Text, TextContainer } from '@shopify/polaris';
import { DuplicateMinor, HideMinor, ViewMinor } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import AccountPlanSetting from '../accountPlan';
import RegularText from '@/components/RegularText';
import BoldText from '@/components/BoldText';
import { GeneralSettingsStyled } from './styled';

function GeneralSettings() {
  const scope = useScope();
  const dispatch = useDispatch();
  const { data, isLoading } = apiCaller.useGetGeneralDetailQuery(config.shop);
  const adminURL = apiCaller.useGetAdminURLQuery(config.shop);
  const settings = apiCaller.useGetGeneralDetailQuery(config?.shop);
  const customizeCode = apiCaller.useGetCustomizeCodeQuery(config.shop || '');
  const [enableApp, enableStatus] = apiCaller.useEnableAppMutation();
  const [state, setState] = useState({
    modalOpen: false,
    copied: false,
    showFullUrl: false,
  });
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(adminURL?.data?.url || '').then(() => {
      setState({ ...state, copied: true });
      dispatch(
        slice.toastSlice.actions.handleToast({
          isOpen: true,
          content: 'Copied',
          error: false,
        }),
      );
    });
  };

  const handleShowUrl = () => {
    setState({ ...state, showFullUrl: true });
  };

  const handleHideUrl = () => {
    setState({ ...state, showFullUrl: false });
  };

  const handleOpenModalEnableApp = () => {
    if (data?.settings.user.enableApp === true) {
      setState({ ...state, modalOpen: true });
    } else {
      enableApp(!data?.settings.user.enableApp).then((res) => {
        if ('data' in res && res.data.state === 0) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        } else {
          dispatch(
            toastSlice.actions.handleToast({
              isOpen: true,
              content: 'Updated',
              error: false,
            }),
          );
        }
      });
    }
  };

  const handleConfirmToggle = () => {
    enableApp(!data?.settings.user.enableApp).then((res) => {
      if ('data' in res && res.data.state === 0) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      } else {
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
    });
    setState({ ...state, modalOpen: false });
  };

  const handleCancelToggle = () => {
    setState({ ...state, modalOpen: false });
  };

  const handleOpenAdminAccess = () => {
    window.open(adminURL.data?.url);
  };
  const handleGetThemeEditorUrl = () => {
    window.open(settings?.data?.settings?.manageSiteUrl);
  };

  return (
    <GeneralSettingsStyled>
      <AccountPlanSetting />
      <div className="mt-16">
        <CustomSettingToggle
          disableStatus={scope.isViewOnly}
          title="Activate Blockify"
          settingToggleProps={{
            enabled: data?.settings.user.enableApp,
            action: {
              loading: isLoading || enableStatus.isLoading,
              onAction: handleOpenModalEnableApp,
            },
          }}
        />
      </div>

      <Banner tone="info" title="Highly recommend">
        <Text as="p" variant="bodyMd">
          Adding our code into your theme store will restrict <b>potential bypass attempts</b>. Rest assured that turning off or
          uninstalling the app will make our code ineffective.
        </Text>
      </Banner>

      <div className="mt-16">
        <LegacyCard title={<BoldText>Restrict Bypass Tricks with Our Code</BoldText>}>
          <LegacyCard.Section>
            <div className="mt-16">
              <BoldText>Instruction:</BoldText>
            </div>
            <div className="mt-8">
              <Text as="p" variant="bodyMd">
                {'1. Navigate to Settings > Scroll down and select '}
                <Button onClick={handleGetThemeEditorUrl} variant="plain">
                  Custom Code
                </Button>
                .
              </Text>
              <Text as="p" variant="bodyMd">
                2. Click 'Add Code' in the Head section
              </Text>
              <Text as="p" variant="bodyMd">
                3. Paste our code into the 'Paste the code snippet here' box.
              </Text>
              <Text as="p" variant="bodyMd">
                4. In the 'Add code to pages' field, choose 'All pages' and select 'Load code once'.
              </Text>
              <Text as="p" variant="bodyMd">
                5. In the 'Place code in' field, select 'Head'.
              </Text>
              <Text as="p" variant="bodyMd">
                6. Click Apply to save the changes.
              </Text>
            </div>
            <div className="customize-code">
              <div className="copy-code">
                <Button
                  icon={DuplicateMinor}
                  onClick={() => {
                    dispatch(
                      slice.toastSlice.actions.handleToast({
                        content: 'Copied',
                        isOpen: true,
                        error: false,
                      }),
                    );
                    navigator.clipboard.writeText(customizeCode?.data?.code || '');
                  }}
                >
                  Copy
                </Button>
              </div>
              <pre>{customizeCode?.data?.code || ''}</pre>
            </div>
          </LegacyCard.Section>
        </LegacyCard>
      </div>
      <LegacyCard title={<BoldText>Admin access URL</BoldText>}>
        <LegacyCard.Section>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
            <div style={{ maxWidth: '90%' }}>
              {adminURL?.isLoading ? (
                <Loading />
              ) : (
                <Link removeUnderline external onClick={handleOpenAdminAccess}>
                  {state.showFullUrl ? adminURL.data?.url : `${adminURL.data?.url?.substring(0, 20)}***************` || ''}
                </Link>
              )}
            </div>
            <div style={{ maxWidth: '10%' }}>
              <LegacyStack spacing="tight">
                <Button
                  icon={state.showFullUrl ? ViewMinor : HideMinor}
                  variant="plain"
                  onClick={state.showFullUrl ? handleHideUrl : handleShowUrl}
                ></Button>
                <Button icon={DuplicateMinor} variant="plain" onClick={handleCopyUrl} />
              </LegacyStack>
            </div>
          </div>
          <RegularText>
            Access via the above URL will not be blocked under any circumstances. Please keep the link disclosed.
          </RegularText>
        </LegacyCard.Section>
      </LegacyCard>

      <Modal
        open={state.modalOpen}
        onClose={handleCancelToggle}
        title="Are you sure that you want to turn off Blockify?"
        primaryAction={{
          content: 'Turn off',
          onAction: handleConfirmToggle,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCancelToggle,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              While turned off, Blockify <b>cannot protect your website</b> from unauthorized and fraudulent visitors.{' '}
              <b>Please make sure that you understand all the risks</b>. Do you still want to proceed?
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </GeneralSettingsStyled>
  );
}

export default GeneralSettings;
